<template>

  <v-container >
    <row-header :msg="msgRowHeader" action="UserNew"></row-header>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              item-key="name"
              class="elevation-1"
              loading
              loading-text="Loading... Please wait"
              v-show="loading"
          ></v-data-table>
          <v-data-table
              dense
              :headers="headers"
              :items="users"
              :footer-props="rowsPerPageItems"
              :items-per-page="pagination"
              item-key="email"
              :search="search"
              v-show="!loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2 green--text"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  small
                  class="red--text"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import DialogConfirm from "../../../components/content/DialogConfirm";
import {DeleteRequest, GetRequest, RequestToken} from '../../../store/services/users';

export default {
  name: 'User',
  components: {
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New', newMsg: 'User\'s List', actionName: 'UserNew'},
    users: [],
    headers: [
      { text: 'No', align: 'end', value: 'no'},
      { text: 'Name', value: 'full_name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone Number', value: 'phone_number' },
      { text: 'Merchant', value: 'bank_code' },
      { text: 'Balance', value: 'current_balance', align: 'end' },
      { text: 'Role', value: 'role_name' },
      // { text: 'Created By', value: 'created_by' },
      // { text: 'Created At', value: 'created_at' },
      { text: 'Action', value: 'actions', sortable: false, },
    ],
    userSelected: {},
  }),
  async mounted() {
    console.log('view page admin user');
    this.loading = true;
    if (this.$store.getters.isLoggedIn) {
      await this.getUserData();
      await this.action.$on('delete-data-user', () => {
        this.deleteExecute(); // temporary //
      })
    }
    this.loading = false;
  },
  beforeDestroy() {
    // this.action.$off('delete-data-user');
    // this.action.$off('open-dialog-conf-user');
  },
  methods : {
    async getUserData() {
      try{
        let response = await GetRequest('users');
        response.data.forEach((val, i) => {
          val.no = i+1;
          val.full_name = val.full_name.length > 15 ? val.full_name.substring(0, 15)+'...' : val.full_name;
          val.email = val.email.length > 17 ? val.email.substring(0, 15)+'...' : val.email;
          val.role_name = val.role ? val.role.name : '-';
          val.current_balance = this.$options.filters.amountFormatNoPrefix(val.current_balance);
          val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
        })
        this.users = response.data;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        if (err === 'Token expired') {
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            this.users = await this.getUserData();
          }
        } else {
          alert('Create user data failed ' + err.message);
        }
      }
    },
    editItem (item) {
      this.userSelected = item;
      this.$router.push({name: 'UserEdit', params: item})
    },
    deleteItem (item) {
      this.userSelected = item;
      this.action.$emit('open-dialog-conf-user', true);
    },
    async deleteExecute () {
      await this.$store.dispatch('req_data')
      this.loading = this.$store.getters.isLoading;
      try {
        let response = await DeleteRequest('users/' + this.userSelected._id);
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        if (response.data && response.message === 'deleted') {
          alert('delete user data success');
          this.users = await this.getUserData();
          return;
        }
        alert('delete user data failed');
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        if (e === 'Token expired') {
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            await this.deleteExecute();
          }
        } else {
          alert('Delete user data failed ' + e.message);
        }
      }
    },
    async refreshToken() {
      try {
        await RequestToken();
        return true;
      } catch (e) {
        alert('error load refresh token');
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>
